import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import customizationReducer from './customizationReducer';
import notification, * as fromNotification from './notificationReducer';
import warningCampaignReducer from './warningCampaignReducer';
import cartReducer from './cartReducer';
import kanbanReducer from './kanbanReducer';
import transferDataReducer from './transferDataReducer'

const rootReducer = combineReducers({
    customization: customizationReducer,
    warningCampaign: warningCampaignReducer,
    transferData: transferDataReducer,
    notification,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer
});

export default rootReducer;

// Selector Functions
export const getNotification = (state) => fromNotification.getNotification(state.notification);
